<template>
    <Content title="Status">
        <template v-slot:content>
          <div class="d-flex justify-center">
            <template v-if="loading">
              <v-card max-width="850px" class="flex-grow-1">
                <v-card-text class="d-flex justify-space-between">
                  <div class="loading">LOADING</div>
                </v-card-text>
              </v-card>
            </template>
            <template v-else>
              <v-card max-width="850px" class="flex-grow-1">
                <v-card-text class="d-flex justify-space-between pb-0">
                  <div class="name font-weight-bold align-self-center ">{{name}}</div>
                  <div class="status"><statuspage-widget src="https://yvls18wm9l59.statuspage.io/" appearance="badge" title="SYSTEM STATUS"></statuspage-widget></div>
                </v-card-text>
                <v-card-text class="pt-0 pb-0">
                  <div v-html="svg" />
                </v-card-text>
                <v-card-text class="d-flex justify-space-between pt-0">
                  <div class="dateRange">{{dateRange}}</div>
                  <hr class="flex-grow-1 align-self-center ma-2"/>
                  <div class="uptime">{{uptime}}</div>
                  <hr class="flex-grow-1 align-self-center ma-2"/>
                  <div class="today">{{today}}</div>
                </v-card-text>
              </v-card>
            </template>
          </div>
        </template>
    </Content>
</template>

<script>
import Content from '@/views/Content.vue'

export default {
  name: "Status",
  components: {
    Content
  },
  data() {
    return {
      loading: true,
      content: '<div data-component-id="znbrt6jsq9qh',
      name: '',
      status: '',
      svg: '',
      dateRange: '',
      uptime: '',
      today: ''
    }
  },
  created() {
    this.getStatusPage();
  },
  methods: {
    getStatusPage() {
      fetch('https://status.infotechinc.com')
        .then(r => r.text())
        .then(t => {
          var parser = new DOMParser();
          var htmlDoc = parser.parseFromString(t, 'text/html');
          var result = htmlDoc.evaluate('//div[@data-component-id="znbrt6jsq9qh"]', htmlDoc, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
          var node = result.iterateNext();
          this.content = node.outerHTML;
          this.name = node.getElementsByClassName("name")[0].innerText.trim();
          this.status = node.getElementsByClassName("component-status")[0].innerText.trim();
          var svg = node.getElementsByTagName('svg')[0];
          svg.setAttribute("width", "100%");
          this.svg = svg.outerHTML;
          this.dateRange = node.getElementsByClassName("legend-item-date-range")[0].innerText.trim();
          this.uptime = node.getElementsByClassName("legend-item-uptime-value")[0].innerText.trim(); //'100.0\n    \n    % uptime'
          this.today = node.getElementsByClassName("legend-item-date-range")[1].innerText.trim();
          this.loading = false;
        });
    } 
  }
};
</script>


